<template>
  <div id="app">
<a-layout id="components-layout-demo-top" class="layout">
<a-layout-header >
  <div class="flexcenter">
   <!--<a-avatar size="large" src="https://www.znffj.com/images/homepage/homepagelogo.png" style="display:inline-block" shape="square" />-->
  <img src="https://www.znffj.com/images/homepage/homepagelogo.png" alt="logo">
  </div>
  <a-menu  mode="horizontal" :style="{ lineHeight: '64px' }">
      <a-menu-item key="mail"> <a  v-on:click="toHome"><a-icon type="mail" /> 首页</a></a-menu-item>
      <a-sub-menu>
        <span slot="title" class="submenu-title-wrapper"
          ><a-icon type="setting" />产品</span
        >
        <a-menu-item-group title="智能设备">
          <a-menu-item key="setting:5">
            <a  @click="ToRobot(7)">
              电表管理柜
            </a>
          </a-menu-item>
          <a-menu-item key="setting:6">
            <a  @click="ToRobot(8)">
              粮仓定制测量仪
            </a>
          </a-menu-item>
          <a-menu-item key="setting:7">
            <a  @click="ToRobot(9)">
              医疗抢救
            </a>
          </a-menu-item>
          <a-menu-item key="setting:8">
            <a  @click="ToRobot(6)">
              智能发放设备
            </a>
          </a-menu-item>
          <a-menu-item key="setting:9">
            <a  @click="ToRobot(5)">
              人证比对仪
            </a>
          </a-menu-item>

        </a-menu-item-group>
        <a-menu-item-group title="机器人">
          <a-menu-item  key="setting:1">
            <a  @click="ToRobot(1)">
              服务机器人
            </a>
          </a-menu-item>
          <a-menu-item key="setting:2" >
            <a  @click="ToRobot(2)">
              消毒机器人
            </a>
          </a-menu-item>
          <a-menu-item key="setting:3">
            <a  @click="ToRobot(3)">
              大厅机器人
            </a>
          </a-menu-item>
          <a-menu-item key="setting:4">
            <a  @click="ToRobot(4)">
              医疗机器人
            </a>
          </a-menu-item>
        </a-menu-item-group>

      </a-sub-menu>
      <a-menu-item key="alipay">
        <a v-on:click="toAboutUs">关于我们</a>
      </a-menu-item>
    </a-menu>
</a-layout-header>

<a-layout-content>
 <router-view></router-view>
</a-layout-content>
    <a-layout-footer style="text-align: center">
     ©2024 by 米茉智能 All Rights Reserved  备案号:鲁ICP备19046076号   鲁公网安备37021302001192号<br/>
     联系方式:卜经理：17706397167
    </a-layout-footer>
</a-layout>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods:{
    toHome(){
      this.$router.push("/home");
    },
    toAboutUs(){
       this.$router.push("/aboutus");
    },
    ToRobot(index){
      if(index==1){
        this.$router.push("/serverrobot");
      }
      else if(index==2){
        this.$router.push("/disrobot");
      }
      else if(index==3){
        this.$router.push("/hallrobot");
      }
      else if(index==4){
        this.$router.push("/medicalrobot");
      }
      else if(index==5){
        this.$router.push("/home");
      }
       else if(index==6){
        this.$router.push("/fafangji");
      }
      else if(index==7){
        this.$router.push("/dianbiaogui");
      }
       else if(index==8){
        this.$router.push("/liangcangyi");
      }
       else if(index==9){
        this.$router.push("/yiliaoche");
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.ant-layout-header{
  display: flex;
  justify-items: center;
  justify-content: space-around;
  align-items: center;
  flex-wrap:warp;
}
.ant-layout-header{
  background-color: #ffffff;
}
.flexcenter{
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
}

.ant-page-header-heading-title2{
  margin-left: 8px;
  font-size: 1.5rem;
  line-height: 64px;
  font-weight: 600;
}
#components-layout-demo-top{
 min-height: 100%;
}
@media (max-width: 576px) {
  .ant-page-header-heading-title2{
    display: none;
  }
  .flexcenter{
    display: none;
  }
}
</style>

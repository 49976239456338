import Vue from 'vue';
import VueRouter from 'vue-router';
import {Button,PageHeader,Descriptions,Statistic,Tag,Tabs,Avatar,Dropdown,Layout,Menu,Icon,Carousel,Row,Col,Card} from "ant-design-vue";
import App from './App.vue';
//主体

Vue.config.productionTip = false
Vue.component(Button.name, Button);
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter).use(PageHeader).use(Descriptions).use(Statistic).use(Tabs).use(Tag).use(Avatar).use(Dropdown).use(Layout).use(Menu).use(Icon).use(Carousel).use(Row).use(Col).use(Card);

let router = new VueRouter({
  mode:'hash',
  routes:[
    {path:"/",name:"home",component:()=>import ("./components/HelloWorld.vue")},
    {path:"/test",name:"test",component:()=>import ("./components/test.vue")},
    {path:"/aboutus",name:"aboutus",component:()=>import ("./components/AboutUs.vue")},
    {path:"/serverrobot",name:"serverrobot",component:()=>import ("./components/ServerRobot.vue")},
    {path:"/disrobot",name:"disrobot",component:()=>import ("./components/DisinfectRobot.vue")},
    {path:"/hallrobot",name:"hallrobot",component:()=>import ("./components/HallRobot.vue")},
    {path:"/medicalrobot",name:"medicalrobot",component:()=>import ("./components/MedicalcareRobot.vue")},
    {path:"/fafangji",name:"fafangji",component:()=>import ("./components/FaFangJi.vue")},
    {path:"/yiliaoche",name:"yiliaoche",component:()=>import ("./components/YiLiaoChe.vue")},
    {path:"/dianbiaogui",name:"diaobiaogui",component:()=>import ("./components/dianbiao.vue")},
   {path:"/liangcangyi",name:"liangcangyi",component:()=>import ("./components/liangcangyi.vue")},
    {path: "*", redirect: "/"}
  ]

});

new Vue({
  router:router,
  render: h => h(App),
}).$mount('#app')
